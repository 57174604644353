import React, { useEffect } from 'react';
import { useSellerColor } from '@lib/hooks/use-seller-color';
import Head from '@modules/common/components/head';
import LoginTemplate from '../../modules/login/template';
import { useAccount } from '../../lib/context/account-context';
import { useRouter } from 'next/router';

const LoginPage = () => {
  const { sellerColor, sellerFontColor } = useSellerColor();
  const { customer } = useAccount();
  const router = useRouter();
  const sellerName = process.env.NEXT_PUBLIC_SELLER_NAME;

  useEffect(() => {
    if (customer && customer.hasAccount) {
      router.push('/');
    }
  }, [customer]);

  return (
    <>
      <Head
        title="Login Page"
        description={`Connectez-vous à l'espace Catalog de ${sellerName}`}
        style={`:root {${sellerColor} ${sellerFontColor}}`}
      />
      {!customer?.hasAccount && <LoginTemplate />}
    </>
  );
};

export default LoginPage;
