import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginForm } from '@lib/hooks/use-login-form';
import Input from '@modules/common/components/input';
import CatalogButton from '@modules/common/components/catalog-button';
import { User } from 'iconoir-react';
import Link from 'next/link';
import { useLogin } from '@lib/context/login-context';

const LoginForm = () => {
  const { ct } = useCatalogTranslation(['common']);
  const { loginSchema } = useLoginForm();
  const { loginPageContent, loginHasFailed, onSubmit } = useLogin();
  const { subTitle, formCta } = loginPageContent;
  type LoginFormType = z.infer<typeof loginSchema>;
  const [hasError, setHasError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    resolver: zodResolver(loginSchema),
  });

  useEffect(() => {
    if (errors.password || errors.email || loginHasFailed) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [loginHasFailed, errors]);

  return (
    <div className="flex flex-col gap-8">
      <p>{subTitle}</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 w-full sm:w-3/4 max-w-[400px] mb-3"
      >
        <div>
          <Input
            label={ct('Login.emailLabel', 'Votre adresse email')}
            showRequiredMark={false}
            id="email"
            type="email"
            required={true}
            {...register('email')}
          />
        </div>
        <div>
          <Input
            label={ct('Login.passwordLabel', 'Votre mot de passe')}
            showRequiredMark={false}
            id="password"
            type="password"
            required={true}
            {...register('password')}
          />
          {hasError && (
            <p className="mt-3 text-sm text-error-50">
              {ct(
                'Login.loginError',
                'Identitfiants incorrects. Veuillez vérifier votre adresse e-mail et votre mot de passe.',
              )}
            </p>
          )}
        </div>
        <CatalogButton color="seller" type="submit" size="large" width="fit">
          <User className="seller" />
          {formCta}
        </CatalogButton>
        <Link href="/login?step=email">
          <a className="text-sm text-gray-500">
            {ct('Login.forgotPassword', 'Mot de passe oublié ?')}
          </a>
        </Link>
      </form>
    </div>
  );
};

export default LoginForm;
