import React from 'react';
import Image from 'next/image';
import { ArrowLeft } from 'iconoir-react';
import { useLogin } from '@lib/context/login-context';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import LanguageDropdown from '../../layout/templates/catalog-nav/language-dropdown';
import Logo from '../../layout/templates/catalog-nav/logo';
import LoginInfoCard from '../components/login-info-card';
import SendEmailForm from '../components/send-email-form';
import DefinePasswordForm from '../components/define-password-form';
import RegistrationForm from '../../registration/form';
import LoginForm from '../components/login-form';

const LoginTemplate = () => {
  const { loginPageContent, loginImage, currentStep, handleBackClick } =
    useLogin();
  const { title, infoCardTitle } = loginPageContent;
  const { ct } = useCatalogTranslation('common');
  const renderForm = () => {
    switch (currentStep) {
      case 'email':
        return <SendEmailForm />;
      case 'password':
        return <DefinePasswordForm />;
      case 'registration':
        return <RegistrationForm />;
      case 'login':
      default:
        return <LoginForm />;
    }
  };

  const displayPreviousButton =
    currentStep !== 'login' && currentStep !== 'registration';

  return (
    <div className="min-h-screen h-screen flex flex-col md:flex-row md:justify-between">
      <div className="flex flex-1 flex-col bg-white p-8 w-full mx-auto gap-10 overflow-auto">
        <nav className="flex h-20 items-center justify-between">
          <Logo />
          <LanguageDropdown />
        </nav>
        <div className="flex flex-col mx-auto gap-4 max-w-[768px]">
          {displayPreviousButton && (
            <button onClick={handleBackClick}>
              <div className="flex items-center gap-2">
                <ArrowLeft className="h-4 w-4" />
                <span className="text-sm">
                  {ct('Login.previousStep', "Revenir à l'étape précédente")}
                </span>
              </div>
            </button>
          )}
          <h1 className="text-4xl font-semibold">{title}</h1>
          {renderForm()}
          {infoCardTitle && <LoginInfoCard />}
        </div>
        <footer className="mt-auto">
          <Image src="/catalog.png" width={60} height={12} alt="Catalog" />
        </footer>
      </div>
      <div className="hidden md:block relative h-full md:w-1/3 lg:max-w-[660px] w-full">
        <div className="hidden md:flex fixed h-full items-center justify-center md:w-1/3 lg:max-w-[660px] bg-gray-100 w-full">
          <Image
            src={loginImage}
            alt="Description de l'image"
            layout="fill"
            objectFit="cover"
            quality={75}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginTemplate;
