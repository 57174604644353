import {
  getAccessibleColor,
  getRGBColor,
} from '../utils/helpers/color-converter';

export const useSellerColor = () => {
  const color = process.env.NEXT_PUBLIC_SELLER_PRIMARY_COLOR || '#000000';
  const sellerColor = getRGBColor(color, 'seller');
  const sellerFontColor = getRGBColor(getAccessibleColor(color), 'sellerFont');

  return { color, sellerColor, sellerFontColor } as const;
};
