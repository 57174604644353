import React from 'react';
import { QuestionMark } from 'iconoir-react';
import { useLogin } from '@lib/context/login-context';
import CatalogButton from '../../../common/components/catalog-button';

const LoginInfoCard = () => {
  const { loginPageContent, goToStep, registrationFormEnabled } = useLogin();
  const {
    infoCardTitle,
    infoCardText,
    infoCardCta,
    infoCardNewClientTitle,
    infoCardNewClientText,
    infoCardNewClientCta,
  } = loginPageContent;

  return (
    <div className="flex p-6 rounded-lg bg-seller/5 gap-4">
      <div className="min-w-8 h-8 border rounded-full border-black flex items-center justify-center">
        <QuestionMark className="w-6 h-6 text-black" />
      </div>
      <div className="flex flex-col gap-3">
        <div>
          <div className="text-sm font-semibold text-black">
            {infoCardTitle}
          </div>
          <div className="text-sm text-black">{infoCardText}</div>
        </div>
        {infoCardCta && (
          <CatalogButton
            color="transparent"
            size="small"
            width="fit"
            onClick={(e) => {
              e.preventDefault();
              goToStep('email');
            }}
          >
            {infoCardCta}
          </CatalogButton>
        )}
        {registrationFormEnabled && infoCardNewClientTitle && (
          <>
            <div>
              <div className="text-sm font-semibold text-black">
                {infoCardNewClientTitle}
              </div>
              <div className="text-sm text-black">{infoCardNewClientText}</div>
            </div>
            {infoCardNewClientCta && (
              <CatalogButton
                color="transparent"
                size="small"
                width="fit"
                onClick={(e) => {
                  e.preventDefault();
                  goToStep('email');
                }}
              >
                {infoCardNewClientCta}
              </CatalogButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginInfoCard;
