import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { Check } from 'iconoir-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLogin } from '@lib/context/login-context';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useLoginForm } from '@lib/hooks/use-login-form';
import CatalogButton from '../../../common/components/catalog-button';
import Input from '../../../common/components/input';
import { useRouter } from 'next/router';

const DefinePasswordForm = () => {
  const { ct } = useCatalogTranslation(['common']);
  const router = useRouter();
  const { query } = router;
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const { definePasswordSchema } = useLoginForm();
  const { onSubmit, loginPageContent } = useLogin();
  const { subTitle, formCta } = loginPageContent;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof definePasswordSchema>>({
    resolver: zodResolver(definePasswordSchema),
  });

  useEffect(() => {
    const token = query?.token as string;
    const email = query?.email as string;
    if (token) setToken(token);
    if (email) setEmail(email);
  }, [query]);

  const submitResetPassword = (data: z.infer<typeof definePasswordSchema>) => {
    const { password, confirmPassword } = data;
    if (!token || !email) return;
    onSubmit({ token, password, email });
  };
  return (
    <div className="flex flex-col gap-8">
      <p>{subTitle}</p>
      <form
        onSubmit={handleSubmit(submitResetPassword)}
        className="flex flex-col gap-8 w-full sm:w-3/4 max-w-[400px]"
      >
        <div>
          <Input
            label={ct('Login.passwordLabel', 'Votre mot de passe')}
            showRequiredMark={false}
            id="password"
            type="password"
            required={true}
            {...register('password')}
          />
        </div>
        <div>
          <Input
            label={ct(
              'Login.confirmPasswordLabel',
              'Confirmez votre mot de passe',
            )}
            showRequiredMark={false}
            id="confirmPassword"
            type="password"
            required={true}
            {...register('confirmPassword')}
          />
          {errors && (
            <p className="mt-3 text-sm text-error-50">
              {errors?.password?.message}
              {errors?.confirmPassword?.message}
            </p>
          )}
        </div>
        <CatalogButton color="seller" type="submit" size="large" width="fit">
          <Check className="seller" />
          {formCta}
        </CatalogButton>
      </form>
    </div>
  );
};

export default DefinePasswordForm;
