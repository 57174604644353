import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useLogin } from '@lib/context/login-context';
import { useLoginForm } from '@lib/hooks/use-login-form';
import Input from '../../../common/components/input';
import CatalogButton from '../../../common/components/catalog-button';
import { Mail } from 'iconoir-react';

const SendEmailForm = () => {
  const { ct } = useCatalogTranslation(['common']);

  const { sendEmailSchema } = useLoginForm();
  const { onSubmit, loginPageContent } = useLogin();
  const { subTitle, formCta } = loginPageContent;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof sendEmailSchema>>({
    resolver: zodResolver(sendEmailSchema),
  });
  return (
    <div className="flex flex-col gap-8">
      <p>{subTitle}</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-8 w-full sm:w-3/4 max-w-[400px]"
      >
        <div>
          <Input
            label={ct('Login.emailLabel', 'Votre adresse email')}
            showRequiredMark={false}
            id="email"
            type="email"
            required={true}
            {...register('email')}
          />
          {errors && errors.email && (
            <p className="mt-3 text-sm text-error-50">{errors.email.message}</p>
          )}
        </div>
        <CatalogButton color="seller" type="submit" size="large" width="fit">
          <Mail className="seller" />
          {formCta}
        </CatalogButton>
      </form>
    </div>
  );
};

export default SendEmailForm;
